.mat-hint {
    color: rgba(255, 255, 255, .6);
}


.mat-radio-outer-circle {
    border-color: white;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--primary);
}



.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--primary);
}

.mat-select-value,
.mat-select-arrow,
.mat-datepicker-toggle {
    color: white;
}





.custom-qrcode img {
    max-width: 100% !important;
}