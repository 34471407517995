.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
}

app-teachers-archive {
	width: 100%;
}





/**
 * Header
 */

/**
 * Top Header
 */
.header-top {
	background-color: black;
	border-bottom: var(--height-line) solid var(--primary);
	height: 56px;
	max-height: 56px;
	z-index: 1099;
	position: relative;
}

.header-top .navbar {
	background-color: transparent;
	position: relative;
}

.header-top .navbar.brand img {
	width: auto;
	height: calc(56px - 14px);
}

.navbar-toggler {
	background-color: #343a40;
}



.navbar-toggler .navbar-toggler-icon {
	font-size: 20px;
	font-weight: bold;
	color: white;
}

.header-top .btn-toogle-lang,
.header-top .dropdown-toggle-split {
	background-color: #131a22;
	color: white;
}

.btn-toogle-lang:hover {
	background-color: #252a30;
	color: white;
}

.btn-toogle-lang .glyphicon {
	margin-right: 10px;
}

/**
 * Main Header
 */

.header-main {
	background-color: var(--base-secondary);
	height: 100px;
}

.header-main>.container {
	position: relative;
}

.header-main>.container>.inner {
	margin-left: calc(140px + 32px + 1.5rem);
}

.header-main .nav-main {
	display: grid;
	gap: 6px;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
}

.header-main .college-brand {
	position: absolute;
	width: 130px;
	height: 130px;
	border-radius: 6px;
	overflow: hidden;
	top: calc(6px + 26px);
	left: 32px;
	background-color: white;
	box-shadow: 0 0 0 4px var(--base-primary), 0 0 0 10px var(--silver), 0 0 0 16px var(--base);
}

.header-main .college-brand img {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.header-main .nav-secondary {
	display: grid;
	grid-template-columns: repeat(8, 36px);
	padding: 5px 8px;
	background-color: #37475a;
	border-radius: 6px;
	gap: 3px;
	margin-left: auto;
}

.header-main .nav-secondary>a {
	/* grid-area: a; */
}

.header-main .nav-secondary>.team-container {
	grid-area: 2 / 7 / 2 / 9;
}

.header-main .userinfo th {
	padding-right: 1rem;
}

/**
 * Header Bottom
 */

.header-bottom {
	box-sizing: border-box;
	background-color: var(--base-dark);
	height: 48px;
	border-bottom: 5px solid black;
}

.header-bottom>.container>.inner {
	margin-left: calc(140px + 32px + 1.5rem);
}

.header-bottom-right {
	display: flex;
}

.header-bottom-right .tools {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: auto;
	gap: 10px;
	margin-bottom: 0;
}

.header-bottom-right .tools .tool-nav-link {
	width: 28px;
	max-width: 28px;
	display: block;
}

.header-bottom-right .tools .tool-nav-link svg,
.header-bottom-right .tools .tool-nav-link img {
	width: 100%;
	max-width: 100%;
}

/**
 * Main
 */

.main-left {
	flex-basis: 300px;
	width: 300px;
	min-width: 300px;
}

.card>.card-body .photo-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	align-content: center;
	padding: 0 10px 0 0;
}

.card>.card-body .photo-container mat-radio-group {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
}

.card>.card-body .photo-container>.photo {
	margin-bottom: 20px;
	background-color: var(--base-primary);
	border-radius: 15px;
	border: 5px solid var(--silver);
	padding: 15px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.card>.card-body .photo-container>.photo svg,
.card>.card-body .photo-container>.photo img {
	width: 160px;
	max-width: 160px;
	height: auto;
}

.card-main {
	box-shadow: unset;
	background-color: transparent;
	border: 0;
}

.card-main>.card-header {
	background-color: transparent;
	padding: 0;
	border: 0;
	border-bottom: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-bottom: 4px solid var(--primary);
}

.card-main>.card-header .card-header-main {
	color: white;
	padding: 6px 155px 6px 30px;
	display: flex;
	align-items: center;
	align-content: center;
	background-color: var(--base-dark);
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}

.card-main>.card-header .card-header-main .card-title {
	font-family: Montserrat, 'Sans Serif';
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: flex-start;
	font-weight: bold;

}

.card-main>.card-header .card-header-main .card-title>.brand {
	margin-right: .75em;
}

.card-main>.card-header .card-header-main .card-tools {
	margin-left: auto;
	display: flex;
	flex-wrap: nowrap;
	gap: 6px;
	padding-right: 7px;
}

.card-main>.card-header .card-header-top {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	align-content: flex-end;
	padding: 0 45px;
}

.card-main>.card-header .card-header-top .card-header-image {
	background-color: var(--base-secondary);
	border-radius: 15px;
	padding: 10px;
	margin-left: 10px;
	margin-bottom: -45px;
	border: 6px solid white;
}

.card-main>.card-header .card-header-top .card-header-image>.card-header-image-inner {
	border: 3px solid var(--silver);
	border-radius: 50%;
	background-color: var(--base-primary);
	overflow: hidden;
	width: 70px;
	max-width: 70px;
	height: 70px;
	max-height: 70px;
	padding: 10px;
}

.card-main>.card-header .card-header-top .card-header-image>.card-header-image-inner img {
	max-width: 100%;
	height: auto;
}

.card-main>.card-header .card-header-top h5 {
	font-family: Montserrat, 'Sans Serif';
	font-weight: bolder;
	line-height: .75em;
	text-align: right;
	margin-bottom: 6 !important;
}

.card-main>.card-header .card-header-top p {
	font-family: Montserrat, 'Sans Serif';
	line-height: 1.2em;
	text-align: right;
	margin-bottom: 6px;
}

.card-main>.card-body {
	background-color: #e6eaf3;
	border-right: 2px solid var(--light-gray);
	border-left: 2px solid var(--light-gray);
	border-bottom: 2px solid var(--light-gray);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.card-main {
	max-height: 100%;
}

.card-title-alt h5 {
	font-weight: bold;
	font-size: 18px;
	line-height: 18px;
	margin-bottom: 6px !important;
	color: var(--base-dark);
}

.card-title-alt p {
	font-size: 16px;
	line-height: 16px;
}

.card-main.single .student-image {
	border-radius: 6%;
	border: 2px solid var(--base-primary);
	width: 110px;
	height: 130px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	top: 10px;
	right: 10px;
	z-index: 1999;
	margin-left: 15px;
	margin-bottom: -48px;
}

.card-body-main {
	max-height: 100%;
	overflow: auto;
}

.card-main .card-body-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px;
}

.card-main .card-body-header .breadcrumb {
	margin-bottom: 0;
}

.card-with-nav .card-body-main {
	position: relative;
	padding-left: 60px !important;
}

.card-with-nav .card-nav {
	position: absolute;
	padding: 15px 6px;
	left: 0;
	transform: translatey(-25%);
	top: 50%;
	background-color: rgba(0, 0, 0, .5);
	max-width: 60px;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	z-index: 19;
}

.card-with-nav .card-nav .card-nav-item {
	border: 3px solid var(--silver);
	background-color: var(--base-primary);
	margin-bottom: 6px;
	padding: 4px 5px;
	border-radius: 6px;
}

.card-with-nav .card-nav .card-nav-item:last-child {
	margin-bottom: 0;
}

.card-with-nav .card-nav .card-nav-item img {
	width: 20px;
	min-width: 20px;
	max-width: 20px;
}

.main-left-top {
	position: relative;
}

.main-left-top::after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 3px;
	left: 0;
	bottom: -15px;
	border-radius: 6px;
	background-color: var(--base-dark);
	opacity: 1;
}


app-students-archive {
	width: 100%;
}

.archive-students {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

.archive-teachers {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
}

.card-teacher .card-header {
	background-color: var(--base-secondary);
	height: 90px;
	border-bottom: 4px solid var(--primary);
}

.card-teacher>.card-header .brand {
	padding: 6px 12px;
	width: 65px;
	height: 65px;
	max-width: 65px;
	max-height: 65px;
	background-color: white;
	display: flex;
	justify-content: center;
	border-radius: 2px;
	margin: 0;
	box-shadow: 0 0 0 2px var(--silver);
	position: absolute;
	top: 10px;
	right: 10px;
}

.card-teacher .card-header .top {
	display: flex;
	justify-content: end;
}

.card-teacher .card-header .top .teacher-image {
	border-radius: 50%;
	width: 80px;
	min-width: 80px;
	height: 80px;
	min-height: 80px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	position: absolute;
	left: 30px;
	top: 50px;
	box-shadow: 0 0 0 2px var(--silver), 0 0 0 10px white;
}

.card-teacher .card-body .teacher-names {
	text-align: center;
	margin-bottom: 20px;
	padding-left: 80px;
}

.card-teacher .card-body .col-1,
.card-teacher .card-body .col-2,
.card-teacher .card-body .col-3,
.card-teacher .card-body .col-4 {
	font-size: 8px;
	line-height: 10px;
	font-weight: bold;
}

.card-teacher {
	background-color: white;
}


.card-student {
	border-radius: 15px !important;
	height: auto;
	background-color: var(--base-secondary);
	overflow: hidden;
	border: 5px solid var(--base-dark);
}

.card-student .card-header {
	border-bottom: unset;
}

.card-student .card-body {
	padding-right: 55px;

}

.card-student .card-body button {
	border: 2px solid var(--light-gray);
}


.nav-card-items {
	display: flex;
	flex-direction: column;
	gap: 1px;
	background-color: rgba(255, 255, 255, .5);
	padding: 10px 2px 10px 4px;
	position: absolute;
	right: 0;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
}

.nav-card-items .button-icon {
	width: 32px;
	min-width: 32px;
	max-width: 32px;
	height: 32px;
	min-height: 32px;
	max-height: 32px;
	border: 2px solid var(--silver);
	padding: 4px;
}

.card-student .card-header .top {
	min-height: 60px;
	background-color: var(--base-primary);
	height: auto;
	padding: 6px;
}

.card-student>.card-header .brand {
	padding: 6px 12px;
	width: 65px;
	height: 55px;
	max-width: 65px;
	max-height: 55px;
	background-color: var(--base-secondary);
	display: flex;
	border-radius: 2px;
	position: absolute;
	left: 40px;
	top: 14px;
	margin: 0;
	justify-content: center;
	box-shadow: 0 0 0 2px var(--silver), 0 0 0 4px var(--base-secondary);
}

.card-student .card-header .top .student-image {
	border-radius: 6%;
	border: 2px solid var(--base-primary);
	width: 80px;
	min-width: 80px;
	height: 100px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;
	position: absolute;
	right: 10px;
	top: 10px;
}



.card-student .card-header .main {
	background-color: var(--gold);
	background: linear-gradient(0deg, rgba(153, 130, 57, 1) 0%, rgba(65, 55, 23, 1) 100%);
	color: white;
	padding: 25px 5px 5px 5px;
	font-size: 12px;
	line-height: 12px;
	font-weight: bold;
	padding-right: 100px;
	border-bottom: 6px solid var(--primary);
}

.card-student .card-header .bottom {
	background-color: var(--base-dark);
	padding: 3px 5px;
	font-size: 14px;
	line-height: 16px;
	color: white;
	font-weight: bold;
	border-top: 4px solid var(--base-primary);
	border-bottom: 4px solid var(--base-primary);
}

.card-student .card-header .bottom .fname {
	text-transform: uppercase;
}

/**
 * Footer
 */

footer {
	background-color: var(--base-secondary) !important;
	border-top: 3px solid var(--silver);
	padding-top: 5px;
	padding-bottom: 5px;
	z-index: 1099;
}

footer nav .nav-link {
	font-size: .75rem !important;
	color: white;
	padding: 0 .5rem;
}

footer.text-sm nav .nav-link {
	padding: 0 .5rem;
}

footer nav .nav-link:hover {
	color: var(--primary);
}

/**
 * Datatables 
 */

.table>tbody>tr.selected>td {
	background-color: var(--primary);
	color: white;
}

/**
 * Swal
 */
.swal2-styled.swal2-confirm {
	background-color: var(--primary) !important;
	border-color: var(--primary) !important;
}


/**
 * Buttons With Icons
 */

.button-icon {
	position: relative;
	width: 40px;
	min-width: 40px;
	max-width: 40px;
	height: 40px;
	min-height: 40px;
	max-height: 40px;
	padding: 6px;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: var(--base-primary) !important;
	border: 3px solid var(--silver);
	border-radius: 6px;
}

.button-icon svg,
.button-icon img {
	width: auto;
	max-width: 100%;
	height: 100%;
	max-height: 100%;
}

.button-icon-sm {
	width: 36px;
	min-width: 36px;
	max-width: 36px;
	height: 36px;
	min-height: 36px;
	max-height: 36px;
	border: 2px solid var(--silver);
	padding: 4px;
}

.button-icon-lg {
	width: 48px;
	min-width: 48px;
	max-width: 48px;
	height: 48px;
	min-height: 48px;
	max-height: 48px;
}

/**
 * Fakes text box
 */

.userinfo .fake-text-box {
	text-align: left;
}

.fake-text-box {
	display: block;
	color: #878a90;
	background-color: #384452;
	padding: .15rem .5rem;
	margin-bottom: 5px;
	border-radius: .2rem;
	/* border-radius: 0; */
}

/**
 * Team icons
 */
.team>div {
	width: 36px;
	height: 36px;
	padding: 0px;
	margin: 0 0 0 -10px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
	display: block;
	background-size: 36px;
	background-position: 50% 50%;
	border: 1px solid var(--gold);
}










/* 
 * Sidebar
 */

.banner {
	display: flex;
}

/* 
 * Sidebar
 */

.sidebar-inner .mat-drawer-inner-container {
	display: flex;
	flex-direction: column;
	max-height: 100%;
}

.sidebar-inner .mat-drawer-inner-container .sidebar-nav {
	overflow: auto;
	padding: 10px;
}

.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 26px 20px 30px 20px;
}

.sidebar>.sidebar-header {}

.sidebar>.sidebar-body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.sidebar>.sidebar-footer {}

.sidebar-inner .mat-card {
	transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
	display: block;
	position: relative;
	padding: 10px;
	border-radius: 4px;
}

.sidebar-inner .mat-card .mat-card-title {
	display: block;
	margin-bottom: 8px;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.2em;
}

.sidebar-inner .mat-card .mat-card-actions,
.sidebar-inner .mat-card .mat-card-subtitle,
.sidebar-inner .mat-card .mat-card-content {
	display: block;
	margin-bottom: 0;
}

.sidebar-inner .mat-card mat-card-image {
	width: 60px;
	max-width: 60px;
}

.sidebar-inner .mat-card mat-card-image img,
.sidebar-inner .mat-card mat-card-image svg {
	width: 100%;
	max-width: 100%;
	height: auto;
}