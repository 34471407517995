:root {
    --base: rgb(254, 254, 254);
    --base-primary: rgb(19, 26, 34);
    --base-secondary: rgb(35, 47, 62);
    --base-dark: rgb(52, 58, 64);
    --primary: rgb(249, 139, 19);
    --primary-active: rgb(216, 91, 8);
    --secondary: rgb(52, 58, 64);
    --dark: rgb(52, 58, 64);
    --gold: rgb(153, 130, 57);
    --silver: rgb(149, 154, 158);
    --height-line: 6px;
    --light-gray: #ececec;

    --color-light: white;

    --border-radius-sm: 6px;
    --border-radius-md: 8px;
    --border-radius: 16px;
}

/* @font-face {
	font-family: 'Aller';
	src: url('../fonts/Aller.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
} */

* {
    /* font-family: 'Aller', 'roboto', Arial, Helvetica, sans-serif; */
    font-family: Montserrat, sans-serif;
}

.bg-base {
    background-color: var(--base) !important;
}

.bg-base-primary {
    background-color: var(--base-primary) !important;
}

.bg-base-secondary {
    background-color: var(--base-secondary) !important;
}

.bg-base-dark {
    background-color: var(--base-dark) !important;
}

.fil0,
.fill-white {
    fill: #FEFEFE
}

.fil1,
.fill-gold {
    fill: #A88E3D
}

.btn {
    transition: background-color 0.2s;
}

.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: none;
}

.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:hover {
    background-color: var(--primary-active);
    border-color: var(--primary-active);
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: 30px;
}

.custom-badge {
    border-radius: 0.2rem;
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
}

.custom-badge.bg-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}










.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    line-height: 1.35;
    border-radius: 0.2rem;
}

.navbar-badge {
    font-size: .6rem;
    font-weight: 300;
    padding: 2px 4px;
    position: absolute;
    right: 5px;
    top: 9px;
}

.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.btn-block {
    display: block;
    width: 100%;
}


.card-main>.card-header .card-header-main .card-title {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.card-title {
    float: left;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0;
    display: flex;
    gap: 10px;
    align-items: center;
}

.custom-material-modal mat-dialog-container .card {
    margin-bottom: 0;
}











.text-xs {
    font-size: .75rem !important;
}




.waves-effect {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.btn.waves-effect {
    -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

@media (min-width: 1280px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1240px;
    }

}

@media (min-width: 1366px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1326px;
    }

}


@media (min-width: 1480px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1460px;
    }

}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #6b6b6b #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 10px;
    border: 3px solid #ffffff;
}


.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .98);
}













.mat-drawer-content {
    max-height: 100% !important;
    overflow: hidden !important;
}