@import url(assets/css/global.css);
@import url(assets/css/main.css);
@import url(assets/css/theme.css);

app-root {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
}

app-pages {
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

app-page404 {
    flex-grow: 1;
    height: 100vh;
    max-height: 100vh;
}

main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.small-box {
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    display: block;
    margin-bottom: 20px;
    position: relative;
}

.small-box>.inner {
    padding: 10px;
}

.small-box h3,
.small-box p {
    z-index: 5;
}

.small-box h3 {
    font-size: 2.2rem;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
    white-space: nowrap;
}

.small-box h3,
.small-box p {
    z-index: 5;
}

.small-box p {
    font-size: 1rem;
}

.small-box .icon {
    color: rgba(0, 0, 0, 0.15);
    z-index: 0;
}

.small-box .icon>i.fa,
.small-box .icon>i.fas,
.small-box .icon>i.far,
.small-box .icon>i.fab,
.small-box .icon>i.glyphicon,
.small-box .icon>i.ion {
    font-size: 70px;
    top: 20px;
}

.small-box .icon>i {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.small-box>.small-box-footer {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}

.card.card-custom {
    border-radius: 0;
}

.card.card-custom>.card-header {
    margin-bottom: 0;
    color: white;
    background-color: #131a22;
}

.card.card-custom>.card-header:first-child {
    border-radius: 0;
}

.custom-material-modal mat-dialog-container {
    color: white;
    background-color: var(--base-dark);
    box-shadow: 0 0 0 6px var(--silver), 0 0.5rem 1rem rgba(0, 0, 0, .5);
    padding: 0;
}

.custom-material-modal mat-dialog-container .card {
    background-color: var(--base-secondary);
    border: 0;
    box-shadow: unset;
}

.custom-material-modal mat-dialog-container .card>.card-header {
    color: white;
    background-color: var(--base-dark);
    border-bottom: var(--height-line) solid var(--primary);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    align-content: center;
}

.custom-material-modal mat-dialog-container .card>.card-footer {
    border-top: 0;
    padding-bottom: 15px;
}

.custom-material-modal .mat-input-element {
    color: white;
}

.custom-material-modal .mat-input-element {
    caret-color: white;
}

.custom-material-modal .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent;
}

.custom-material-modal .mat-form-field-ripple {
    background-color: white;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: rgba($color: #ffffff, $alpha: 0.2);
}

.custom-material-modal .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: var(--primary);
}

.custom-material-modal .mat-form-field.mat-focused .mat-form-field-label,
.custom-material-modal .mat-form-field-label {
    color: white;
}

.modal .modal-content .modal-header {
    color: white;
    background-color: var(--base-dark);
    border-bottom: var(--height-line) solid var(--primary);
}

.modal .modal-content .modal-footer {
    color: white;
    background-color: var(--base-secondary);
    border-top: 0;
}

.modal .modal-content .modal-body {
    color: white;
    background-color: var(--base-secondary);
}

.modal-content {
    box-shadow: 0 0 0 6px var(--silver), 0 0.5rem 1rem rgba(0, 0, 0, .5);
}


.grid-student-register {
    display: grid;
    grid-template-columns: 250px 750px;
}

.grid-student-register .photo-container {
    grid-area: 1 / 1 / 4 / 1;
}


.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    background-color: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-page .app-title-login {
    margin-top: 35px;
}

.login-page .app-title-login h5 {
    color: #40525f;
    font-weight: bolder;
    border-bottom: 3px solid #40525f;
    display: inline-block;
    padding: 0px 10px 5px;
}

.login-page .app-title-login p {
    color: white;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
}

.login-box {
    z-index: 1;
}

.login-box,
.register-box {
    width: 560px;
}

.form-login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}

.form-login .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

html,
body {
    height: 100vh;
    width: 100vw;
    overflow: hidden;

}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}